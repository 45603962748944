<template>
  <v-app
    id="inspire"
    style="background: rgb(195,77,34);
    background: linear-gradient(0deg, rgba(195,77,34,1) 0%, rgba(253,156,45,1) 100%);"
  >
    <v-layout justify-center>
      <v-card
        style="margin:auto; border-radius: 20px"
        :min-width="$vuetify.breakpoint.mdAndUp ? '380px' : '330px'"
        min-height="583px"
      >
        <div>
          <v-img
            :src="require('@/assets/login-vertical-gris.png')"
            height="125px"
            contain
            style="border: 25px solid transparent;"
            class="mt-2"
          />
        </div>
        <template v-if="!invalid_token">
          <div>
            <v-layout justify-center>
              <v-icon
                size="50px"
                color="black"
              >
                fal fa-lock-open-alt
              </v-icon>
            </v-layout>
          </div>
          <v-card-text>
            <v-flex
              xs12
            >
              <p
                align="center"
                style="font-size: 19px; font-weight: bold; color:#424243;"
              >
                Crea tu nueva contraseña
              </p>
              <div
                v-if="!successful_change"
                style="margin:0px 40px 0px 0px;"
              >
                <v-text-field
                  v-model.trim="password"
                  :append-icon="showPass ? 'fal fa-eye' : 'fal fa-eye-slash'"
                  :type="showPass ? 'text' : 'password'"
                  full-width
                  autofocus="autofocus"
                  maxlength="150"
                  prepend-icon="fal fa-user"
                  name="login"
                  label="Nueva contraseña"
                  :error-messages="passwordErrors"
                  box
                  @click:append="showPass = !showPass"
                />
                <v-text-field
                  v-model.trim="password_confirm"
                  :append-icon="showPassCon ? 'fal fa-eye' : 'fal fa-eye-slash'"
                  :type="showPassCon ? 'text' : 'password'"
                  style="margin-left: 34px"
                  full-width
                  maxlength="150"
                  name="login"
                  label="Confirmar contraseña"
                  :error-messages="$v.password_confirm.sameAsPassword ? ''
                    : 'La contraseña no coincide.'"
                  box
                  @click:append="showPassCon = !showPassCon"
                />
                <v-layout
                  justify-center
                  style="margin-bottom: 20px"
                >
                  <template>
                    <v-btn
                      :disabled="!$v.password_confirm.sameAsPassword"
                      color="info2"
                      type="submit"
                      class="white--text text-none"
                      style="width: 90%; font-size: 15px; margin: 0px 0px 0px 34px;"
                      @click.prevent="submit"
                    >
                      Cambia tu contraseña
                    </v-btn>
                  </template>
                </v-layout>
              </div>
            </v-flex>
          </v-card-text>
          <template v-if="successful_change">
            <v-alert
              v-model="successful_change"
              color="#F39C12"
              text-color="white"
              class="mb-4"
              style="height: 60px; width: 286px;"
            >
              <v-layout
                justify-center
              >
                <v-icon
                  class="mr-3 mb-2"
                  color="white"
                >
                  fal fa-check
                </v-icon>
                <p
                  class="mb-1"
                  style="font-size: 13px; color:white;"
                >
                  La contraseña ha sido actualizada correctamente.
                </p>
              </v-layout>
            </v-alert>
            <v-btn
              color="info2"
              style="width: 75%; font-size: 15px; margin: 0px 0px 100px 50px;"
            >
              <router-link
                :to="{name: 'home'}"
                target="_blank"
                style="text-decoration: none;"
                class="white--text text-none"
              >
                Encuentra tu empleo ideal
              </router-link>
            </v-btn>
          </template>
          <v-card-actions class="mt-5">
            <v-layout
              justify-space-around
            >
              <p
                style="font-size: 14px; color:#01ACC1; font-weight: bold; cursor: pointer"
                @click="login_dialog = true"
              >
                Inicia sesión
              </p>
              <p
                style="font-size: 14px; color:#01ACC1; font-weight: bold; cursor: pointer"
                @click="signup_modal = true"
              >
                ¿No tienes cuenta? Regístrate
              </p>
            </v-layout>
          </v-card-actions>
        </template>
        <template v-else>
          <v-card-text style="margin-top: 75px">
            <div class="mb-3">
              <v-layout justify-center>
                <v-icon
                  size="60px"
                  color="black"
                >
                  fal fa-exclamation-circle
                </v-icon>
              </v-layout>
            </div>
            <p
              align="center"
              style="font-size: 25px; font-weight: bold; color:#424243;"
            >
              El link para restablecer <br> la contraseña ha expirado.
            </p>
          </v-card-text>
        </template>
      </v-card>
    </v-layout>
    <v-dialog
      v-model="signup_modal"
      max-width="432"
      persistent
    >
      <CandidateSignup
        @signupDone="finishSignup"
        @closeModal="signup_modal = false; login_dialog = true"
      />
    </v-dialog>
    <v-dialog
      v-model="login_dialog"
      width="400"
    >
      <LoginCard @loginSuccess="loginSuccess" />
    </v-dialog>
    <LoadingComponent />
  </v-app>
</template>

<script>
import axios from 'axios';
import { sameAs } from 'vuelidate/lib/validators';
import { parseJwt } from '@/utils/jwt';
import LoadingComponent from '@/components/misc/LoadingComponent.vue';
import CandidateSignup from '@/components/forms/CandidateSignup.vue';
import LoginCard from '@/components/forms/LoginCard.vue';
import JWTConfigMixin from '@/components/mixins/JWTConfigMixin.vue';

export default {
  name: 'NewPassword',
  components: {
    LoadingComponent,
    CandidateSignup,
    LoginCard,
  },
  mixins: [JWTConfigMixin],
  data() {
    return {
      login_dialog: false,
      signup_modal: false,
      successful_change: false,
      invalid_token: false,
      password: '',
      password_confirm: '',
      passwordErrors: [],
      respErrors: null,
      showPass: false,
      showPassCon: false,
    };
  },
  validations: {
    password_confirm: {
      sameAsPassword: sameAs('password'),
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.validateToken();
    });
  },
  methods: {
    validateToken() {
      const payload = {
        token: this.$route.query.token,
      };
      axios.post(this.$store.state.endpoints.validateToken, payload)
        .catch(() => {
          this.invalid_token = true;
        });
    },
    submit() {
      this.$store.commit('setLoading');
      const payload = {
        password: this.password,
        token: this.$route.query.token,
      };
      axios.post(this.$store.state.endpoints.passwordConfirm, payload)
        .then(resp => {
          this.$store.commit('unsetLoading');
          this.successful_change = true;
          console.log(resp);
        })
        .catch(error => {
          console.log(error.response.status);
          this.passwordErrors = error.response.data.password;
          this.$store.commit('unsetLoading');
        });
    },
    loginSuccess() {
      this.$router.push('/');
    },
    finishSignup(payload) {
      this.signup_modal = false;
      this.username = payload.username;
      this.password = payload.password;
      this.$emit('signupDone', '');
      this.authenticate();
    },
    authenticate() {
      this.$store.commit('setLoading');
      this.errorchip = false;
      this.passErrors = [];
      this.userErrors = [];

      const payload = {
        username: this.username,
        password: this.password,
      };

      axios.post(this.$store.state.endpoints.obtainJWT, payload)
        .then(response => {
          this.$store.commit('updateToken', response.data.token);
          this.$store.commit('setAdmin', parseJwt(this.$store.state.jwt).is_admin);
          if (!this.$store.state.isAdmin) {
            axios.get(this.$store.state.endpoints.candidates.getLoginData, this.jwtConfig)
              .then(resp => {
                this.$store.commit('setLoginDetails', resp.data);
                this.$store.commit('unsetLoading');
                this.$emit('loginSuccess', '');
                this.$router.push('/');
              });
          } else {
            this.$store.commit('setAdminName', parseJwt(this.$store.state.jwt).username);
            this.$store.commit('unsetLoading');
            this.$emit('loginSuccess', '');
          }
        })
        .catch(error => {
          this.$store.commit('unsetLoading');
          if (error.response.data.non_field_errors) {
            this.errorchip = true;
            this.chipMsg = error.response.data.non_field_errors.pop();
          } else {
            if (error.response.data.username) {
              this.userErrors = [...error.response.data.username];
            }
            if (error.response.data.password) {
              this.passErrors = [...error.response.data.password];
            }
          }
        });
    },
  },
};
</script>
